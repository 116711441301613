<script setup lang="ts">
  import { FormKitSchema } from '@formkit/vue'
  import { useDebounceFn } from '@vueuse/core'
  import type { FormKitSchemaCondition, FormKitSchemaNode } from '@formkit/core'
  import { formHelpers } from '~/utils/form-helpers'
  import hasSlotContent from '~/utils/has-slot-content'

  const router = useRouter()
  const route = useRoute()

  const props = defineProps<{
    schema?: FormKitSchemaNode[]
    modelValue?: object
    data?: object
    actions?: boolean
    routeQuery?: boolean
    resetParams?: string[]
  }>()

  const emit = defineEmits(['update:modelValue', 'submit'])

  const parsedSchema = computed(() => {
    if (!props.schema) {
      return []
    }

    const schema = [...props.schema]

    const latIndex = schema.findIndex((item) => item.name === 'gps_latitude')
    const lngIndex = schema.findIndex((item) => item.name === 'gps_longitude')
    if (latIndex > 0) {
      const value = schema[latIndex].value && schema[lngIndex].value
        ? {
          lat: parseFloat(schema[latIndex].value),
          lng: parseFloat(schema[lngIndex].value),
        }
        : null
      schema.splice(latIndex, 0, {
        $formkit: 'map',
        name: 'location',
        label: 'Poloha projektu na mapě',
        help: 'Kliknutím na mapu nebo přetažením značky můžete nastavit polohu projektu.',
        value
      })
    }

    return schema.map((node) => {
      if (node.$formkit === 'url' && !node.validation) {
        node.$formkit = 'text'
        node.validation = 'url'
        node.validationMessages = {
          url: 'Zadejte prosím kompletní URL včetně https://'
        }
      }

      if (node.$formkit === 'repeater' && node.value === null) {
        node.value = []
      }

      if (node.$formkit === 'file' && node.accept === '.jpg,.jpeg,.png') {
        node.$formkit = 'image'
      }

      if (['gps_latitude', 'gps_longitude'].includes(node.name)) {
        node.$formkit = 'hidden'
      }

      if (node.name === 'project_from') {
        node.$formkit = 'number'
        node.validation = [['required'], ['max', formData.value.project_to]]
      }

      if (node.name === 'project_to') {
        node.$formkit = 'number'
        node.validation = [['required'], ['min', formData.value.project_from]]
      }

      if (node.name === 'realization_from') {
        node.$formkit = 'number'
        node.validation = [['required'], ['max', formData.value.realization_to]]
      }

      if (node.name === 'realization_to') {
        node.$formkit = 'number'
        node.validation = [['required'], ['min', formData.value.realization_from]]
      }

      return node
    })
  })

  const setRouteQuery = useDebounceFn((value) => {
    useForIn(value, (val, key) => {
      const field = props.schema.find(({ name }) => name === key)
      if (field?.$formkit === 'rangeSlider') {
        if (isArray(val) && val[0] === field?.min && val[1] === field?.max) {
          value[key] = [undefined, undefined]
        }
      } else if (!value[key] || value[key] === '0') {
        value[key] = undefined
      }
    })

    router.replace({
      query: {
        ...useOmit(route.query, props.resetParams),
        ...value,
      },
    })
  }, 300)

  const formData = computed({
    get: () => {
      if (props.routeQuery) {
        const data = props.schema.map(({ $formkit, name, min, max, options }) => {
          if ($formkit === 'rangeSlider') {
            const val = route.query?.[name]
            if (!val || useCompact(val).length === 0) {
              return { [name]: [min, max] }
            }
          }

          if (!route.query?.[name]) {
            return null
          }

          const isCheckboxGroup = ($formkit === 'checkbox' && options?.length > 0)

          if (isCheckboxGroup && !isArray(route.query[name])) {
            return { [name]: [route.query[name]] }
          }

          return { [name]: route.query[name] }
        })

        return useExtend.apply({}, data)
      }
      return props.modelValue
    },
    set: value => {
      emit('update:modelValue', value)
    },
  })

  function onChange () {
    if (props.routeQuery) {
      setRouteQuery(formData.value)
      emit('update:modelValue', formData.value)
    }
  }

  function onSubmit (data, node) {
    emit('submit', formData, node)
  }

  function rootClasses (sectionKey, node) {
    const type = node.props.type
    return {
      [`app-form__${sectionKey}`]: true,
      'u-hide-visually': sectionKey === 'input' && ['radio', 'checkbox'].includes(type),
      'u-list-unstyled': ['options', 'messages'].includes(sectionKey),
    }
  }

  const app = useNuxtApp()
  const slots = useSlots()
  const hasActions = hasSlotContent(slots.actions)
</script>

<template>
  <div class="app-form">
    <FormKit
      @submit="onSubmit"
      @change="onChange"
      type="form"
      :config="{ rootClasses }"
      :actions="actions"
      v-model="formData">
      <FormKitSchema
        :data="{ ...data, ...formData, ...formHelpers }"
        :schema="parsedSchema"/>
      <slot></slot>
      <div
        v-if="hasActions"
        class="app-form__actions">
        <slot name="actions"></slot>
      </div>
    </FormKit>
  </div>
</template>

<style scoped>
  .app-form__actions {
    padding-top: var(--gap-200);
    display: flex;
    max-width: 768px;
  }

  .app-form :deep() {

    & table {
      width: 100%;
    }

    /* Wrapper */
    & .app-form__outer {
      &:not(:last-of-type) {
        margin-bottom: var(--gap-400);
      }
    }

    /* Labels */

    & .app-form__label {
      font-family: var(--font-family-monospace);
      font-size: var(--font-size-200);
    }

    & .app-form__legend {
      padding-top: var(--gap-100);
      font-size: var(--font-size-200);
      font-family: var(--font-family-monospace);
    }

    & .app-form__help {
      padding-top: var(--gap-100);
      font-size: var(--font-size-100);
    }

    & .app-form__outer[data-invalid][data-family="text"] .app-form__help {
      display: none;
    }

    /* Messages */

    & .app-form__outer .app-form__messages {
      font-size: var(--font-size-100);

      & .app-form__message {
        padding-top: var(--gap-100);
        color: var(--c-error);
      }
    }


    & .app-form__form > .app-form__messages {
      margin-top: var(--gap-400);
      margin-bottom: var(--gap-400);

      & .app-form__message {
        color: var(--c-background-light);
        background: var(--c-error);
        padding: var(--gap-350) var(--gap-400) var(--gap-350) var(--gap-600);
        display: flex;
        align-items: center;
        font-size: var(--font-size-300);
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: var(--gap-200);
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          border: var(--gap-50) solid var(--c-background-light);
          border-radius: 50%;
          width: var(--gap-500);
          height: var(--gap-500);
          flex-shrink: 0;
          background: url(~/assets/images/exclamation.svg) no-repeat center center;
          background-size: var(--gap-450);
        }
      }
    }

    /* Option groups */

    & .app-form__fieldset {
      border: 0;
      padding: 0;
      margin: 0;
      min-width: 0;
    }

    & .app-form__options {
      padding-top: var(--gap-300);
    }

    & .app-form__options.is-inline {
      display: flex;
      gap: var(--gap-400);
      & .app-form__option {
        margin-bottom: 0;
      }
    }

    & .app-form__options.is-inline.is-2-col {
      display: grid;
      grid-gap: var(--gap-400);
      grid-template-columns: 3fr 2fr;
      & .app-form__option {
        margin-bottom: 0;
      }
    }

    & .app-form__option {
      &:not(:last-of-type) {
        margin-bottom: var(--gap-300);
      }
      position: relative;
      display: flex;
    }

    & .app-form__option-hint {
      position: relative;
      top: -5px;
      margin-left: var(--gap-200);
    }

    & .app-form__outer {
      &.is-inline {
        margin-bottom: 0;
      }
    }

    & h2:not(:first-of-type) {
      margin-top: var(--gap-500);
    }

    /* Basic text field */
    & .app-form__outer[data-type="textarea"],
    & .app-form__outer[data-type="select"],
    & .app-form__outer[data-family="text"] {
      & .app-form__input {
        border: 1px solid var(--c-gray-400);
        border-radius: 0;
        font-family: var(--font-family);
        font-size: var(--font-size-400);
        padding: var(--gap-300) var(--gap-300);
        transition: var(--s-normal);
        box-sizing: border-box;
        display: block;
        width: 100%;
        @media (--sm) {
          padding: var(--gap-250) var(--gap-300);
        }
        @media (--lg) {
          padding: var(--gap-225) var(--gap-300);
        }

        &:hover {
          border-color: var(--c-gray-450);

          & + .app-form__suffixIcon,
          & + .app-form__suffix {
            border-color: var(--c-gray-450);
          }
        }

        &:focus {
          border-color: var(--c-primary);
          outline: 0;
          & + .app-form__suffixIcon,
          & + .app-form__suffix {
            border-color: var(--c-primary);
          }
        }


        &:not(:last-child) {
          border-right: 0;
        }
      }

      &.is-inline {
        & .app-form__wrapper {
          display: flex;
          gap: var(--gap-300);
          align-items: center;
        }

        & .app-form__label {
          white-space: nowrap;
        }
      }

      &[data-errors] .app-form__label,
      &[data-invalid] .app-form__label {
        color: var(--c-error);
      }

      &[data-errors] .app-form__input,
      &[data-invalid] .app-form__input {
        border-color: var(--c-error);
        & + .app-form__suffixIcon,
        & + .app-form__suffix {
          border-color: var(--c-error);
        }
      }

      & .app-form__label {
        display: block;
        margin-bottom: var(--gap-100);
      }
    }

    & .app-form__outer[data-type="textarea"],
    & .app-form__outer[data-family="text"],
    & .app-form__outer[data-type="file"] {
      & .app-form__inner {
        max-width: 768px;
      }
    }

    & .app-form__outer[data-type="number"] {
      & .app-form__help {
        max-width: 600px;
      }

      & .app-form__inner {
        max-width: 320px;
      }

      & .app-form__input {
        font-family: var(--font-family-monospace);
        min-width: calc(4.5ch + (var(--gap-300) * 2));

        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          appearance: none;
          margin: 0;
        }
      }
    }

    & .app-form__outer[data-type="textarea"] {
      & .app-form__input {
        min-height: 148px;
        font-size: var(--font-size-300);
      }
    }

    & .app-form__outer[data-type="file"] {
      & .app-form__inner {
        display: block;
      }

      & .app-form__input {
        border-right: 1px solid var(--c-gray-400) !important;
      }

      & .app-form__noFiles {
        display: block;
        width: 100%;
        padding-top: var(--gap-100);
        font-size: var(--font-size-100);
      }

      & .app-form__fileItem {
        padding-left: 0;
        margin-top: var(--gap-200);
        margin-bottom: 0;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        &::before {
          display: none;
        }
      }

      & .app-form__fileItem {
        font-size: var(--font-size-100);
      }
      & .app-form__fileRemove {
        font-size: var(--font-size-100);
        color: var(--c-error);
        padding: 0 0 0 var(--gap-300);
        line-height: var(--line-height);

      }
    }

    & .app-form__outer[data-type="select"] {
      & .app-form__input {
        appearance: none;
        padding-right: calc(var(--gap-500) + var(--gap-300));
      }

      & .app-form__inner {
        position: relative;
        max-width: 640px;

        &::after {
          content: '';
          box-sizing: border-box;
          height: 100%;
          aspect-ratio: 1/1;
          border: solid 1px var(--c-gray-400);
          background: var(--c-white);
          position: absolute;
          right: 0;
          top: 0;
          pointer-events: none;
        }

        &::before {
          content: '';
          border-style: solid;
          border-width: 2px 2px 0 0;
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          position: absolute;
          top: 0.8em;
          right: 0.9em;
          transform: rotate(135deg);
          vertical-align: top;
          pointer-events: none;
          color: var(--c-primary);
          z-index: 10;
        }

        &:focus-within {
          &::before {
            color: var(--c-primary);
          }

          &::after {
            border-color: var(--c-primary);
          }
        }
      }
    }

    /* Radio and checkbox fields */

    & .app-form__outer[data-family="box"] {
      & .app-form__wrapper {
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        line-height: 1.2;
      }

      & .app-form__label {
        font-size: var(--font-size-300);
        font-family: var(--font-family);
      }

      & .app-form__inner {
        display: inline-block;
        margin-right: var(--gap-200);
      }

      & .app-form__decorator {
        display: block;
        position: relative;
        width: var(--gap-300);
        height: var(--gap-300);
        background: var(--c-white);
        border: solid var(--gap-50) var(--c-primary);
      }

      & .app-form__input:checked + .app-form__decorator {
        background: var(--c-primary);
      }

      & .app-form__input:focus-visible + .app-form__decorator {
        border-color: var(--c-text);
      }

      &[data-disabled] {
        & .app-form__decorator {
          background: var(--c-white);
          border-color: var(--c-gray-500);
          cursor: not-allowed;
        }

        & .app-form__input:checked + .app-form__decorator {
          background: var(--c-gray-500);
        }
      }
    }

    & .app-form__outer[data-type="radio"] {
      & .app-form__decorator {
        border-radius: 50%;
      }

      & .app-form__input:checked + .app-form__decorator {
        &::after {
          content: '';
          box-sizing: border-box;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: var(--c-primary);
          border: solid var(--gap-50) var(--c-white);
        }
      }
    }

    & .app-form__outer[data-type="checkbox"] {
      & .app-form__input:checked + .app-form__decorator {
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: url(~/assets/images/check.svg) no-repeat center center / contain;
        }
      }
    }

    & .app-form__outer[data-type="rangeSlider"] {
      & .app-form__label {
        display: block;
        margin-bottom: var(--gap-200);
      }
    }


    & .app-form__outer[data-type="hint"] {
      display: inline-block;
    }

    & .app-form__suffixIcon,
    & .app-form__suffix {
      display: flex;
      align-items: center;
      border: 1px solid var(--c-gray-400);
      border-left: 0;
      font-family: var(--font-family);
      font-size: var(--font-size-300);
      line-height: 1;
      padding: var(--gap-300) var(--gap-300) var(--gap-300) 0;
      transition: var(--s-normal);
      white-space: nowrap;
    }

    & .app-form__suffixIcon {
      border-left: 1px solid var(--c-gray-400);
      padding: 0 var(--gap-300);
      cursor: pointer;
      color: var(--c-primary);
      &:hover {
        background: var(--c-primary);
        & path {
          fill: var(--c-white);
        }
      }
      & svg {
        width: 24px;
        height: 24px;
      }
    }


    & .app-form__input:disabled {
      background: var(--c-background);
      & + .app-form__suffixIcon,
      & + .app-form__suffix {
        background: var(--c-background);
      }
    }

    & .app-form__inner {
      display: flex;
    }

    & .marker-color-legend {
      display: inline-block;
      width: var(--gap-350);
      height: var(--gap-350);
      background: var(--c-background);
      border-radius: 50%;
      position: absolute;
      right: 0;
      top: 2px;
    }

    & .app-form__input[value="regular"] + span + .marker-color-legend {
      background: var(--c-green);
    }

    & .app-form__input[value="unverified"] + span + .marker-color-legend {
      background: var(--c-white);
      width: calc(var(--gap-350) - var(--gap-50) * 2);
      height: calc(var(--gap-350) - var(--gap-50) * 2);
      border: var(--gap-50) solid var(--c-green);
    }

    & .app-form__input[value="irregular"] + span + .marker-color-legend {
      background: var(--c-black);
    }

    & .app-form__input[value="reservation"] + span + .marker-color-legend {
      background: var(--c-orange);
    }

    & .app-form__input[name="has_city_architect"][value="0"] + span + .marker-color-legend {
      background: transparent;
    }

    & .app-form__input[name="has_city_architect"][value="1"] + span + .marker-color-legend {
      background: var(--c-red);
    }

    & .app-form__input[name="has_city_architect"][value="2"] + span + .marker-color-legend {
      background: var(--c-gray-500);
    }

    /* visually hide honeypot field */
   & .app-form__outer:has([id="severity"]) {
      display: none;
    }
  }
</style>
