import { roundTo } from 'round-to'
import { formatCurrency } from '~/utils/currency'

export const formHelpers = {
  numeric: val => val ? parseFloat(val) : 0,
  count: val => val.length,
  ceil: val => Math.ceil(val),
  roundTo: (val, precision = 2) => val ? roundTo(parseFloat(val), precision) : null,
  formatNumber: val => val ? val.toLocaleString('cs-CZ') : 0,
  formatCurrency: val => formatCurrency(val),
}
