import revive_payload_client_bc2CobZX4W from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.1_typescript@5.3.3_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_SkUO1oh8IA from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.1_typescript@5.3.3_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ChhYqaXIvt from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.1_typescript@5.3.3_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_o3k0sFIWj7 from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.19.1_vite@5.3.5_vue@3.4.34/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_90D0f7XzAK from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.1_typescript@5.3.3_vite@5.3.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_lxMyQeBwrg from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.1_typescript@5.3.3_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Uelu194bK8 from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.1_typescript@5.3.3_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_APCSSmWWHc from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.1_typescript@5.3.3_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_OFPM1xFAnq from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.1_typescript@5.3.3_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_M8ACROyZ9u from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/v-plausible@1.2.0_rollup@4.19.1_vue@3.4.34/node_modules/v-plausible/dist/runtime/plugin.mjs";
import plugin_rBSkVes8aj from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.19.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_QIP9Nelswj from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_rollup@4.19.1_vue@3.4.34/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import motion_wRam27Kdtb from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/@vueuse+motion@2.1.0_rollup@4.19.1_vue@3.4.34/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import formkitPlugin_pZqjah0RUG from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/.nuxt/formkitPlugin.mjs";
import autoAnimate_4HQGapz9xs from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/autoAnimate.ts";
import gtag_client_Zw8EQXNVTz from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/gtag.client.ts";
import mdijs_BLSoYUWyMD from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/mdijs.ts";
import plone_UkIciQOizT from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/plone.ts";
import vueEasyLightbox_uNpOtA28HR from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/vueEasyLightbox.ts";
import vueGoogleMaps_8xmoAwKZvr from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/vueGoogleMaps.ts";
import vueObserveVisibility_u8X0s2VLui from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/vueObserveVisibility.ts";
export default [
  revive_payload_client_bc2CobZX4W,
  unhead_SkUO1oh8IA,
  router_ChhYqaXIvt,
  _0_siteConfig_o3k0sFIWj7,
  payload_client_90D0f7XzAK,
  navigation_repaint_client_lxMyQeBwrg,
  check_outdated_build_client_Uelu194bK8,
  chunk_reload_client_APCSSmWWHc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OFPM1xFAnq,
  plugin_M8ACROyZ9u,
  plugin_rBSkVes8aj,
  i18n_QIP9Nelswj,
  motion_wRam27Kdtb,
  formkitPlugin_pZqjah0RUG,
  autoAnimate_4HQGapz9xs,
  gtag_client_Zw8EQXNVTz,
  mdijs_BLSoYUWyMD,
  plone_UkIciQOizT,
  vueEasyLightbox_uNpOtA28HR,
  vueGoogleMaps_8xmoAwKZvr,
  vueObserveVisibility_u8X0s2VLui
]