import { default as _91_46_46_46slug_93yr2yTUNdJDMeta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/[...slug].vue?macro=true";
import { default as loginjD2BXjAmn4Meta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/forum/login.vue?macro=true";
import { default as indexP7Odkg552gMeta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/komora/o-komore/logo-ceske-komory-architektu/index.vue?macro=true";
import { default as _91token_93h5dxMiwMa6Meta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/passwordreset/[token].vue?macro=true";
import { default as registerQiz1Ij0v9jMeta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/register.vue?macro=true";
import { default as searchwvmn76TQDZMeta } from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/search.vue?macro=true";
export default [
  {
    name: "slug___cs",
    path: "/:slug(.*)*",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "forum-login___cs",
    path: "/forum/login",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/forum/login.vue").then(m => m.default || m)
  },
  {
    name: "forum-login___en",
    path: "/forum/login",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/forum/login.vue").then(m => m.default || m)
  },
  {
    name: "komora-o-komore-logo-ceske-komory-architektu___cs",
    path: "/komora/o-komore/logo-ceske-komory-architektu",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/komora/o-komore/logo-ceske-komory-architektu/index.vue").then(m => m.default || m)
  },
  {
    name: "komora-o-komore-logo-ceske-komory-architektu___en",
    path: "/komora/o-komore/logo-ceske-komory-architektu",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/komora/o-komore/logo-ceske-komory-architektu/index.vue").then(m => m.default || m)
  },
  {
    name: "passwordreset-token___cs",
    path: "/passwordreset/:token()",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/passwordreset/[token].vue").then(m => m.default || m)
  },
  {
    name: "passwordreset-token___en",
    path: "/passwordreset/:token()",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/passwordreset/[token].vue").then(m => m.default || m)
  },
  {
    name: "register___cs",
    path: "/register",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___en",
    path: "/register",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "search___cs",
    path: "/search",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/home/grunghi/projects/cka-frontend/.temp/cka-frontend/pages/search.vue").then(m => m.default || m)
  }
]